import React, { Component } from "react";
import { Col, Container, Row } from "reactstrap";
import CountUp from "react-countup";

import { Link } from "react-router-dom";
import { AiOutlineTeam } from "react-icons/ai";
import { BsPerson, BsPersonPlus } from "react-icons/bs";
import HomeUrl from "../assets/images/home-border.png";

export default class Services extends Component {
  constructor(props) {
    super(props);
    this.state = {
      services: [
        {
          id: "service1",
          // icon: "mdi-calendar-outline",
          icon: <AiOutlineTeam style={{fontSize:"2rem"}}/>,
          title: "Team Management",
          description:
            "The process of organizing & tracking tasks easily & quickly, either manually or through automation.",
          child: [
            "Create task easily",
            "Assign task to individual or team",
            "Track deadlines of tasks",
            "Set task priorities and distinguish between urgent and group tasks",
            "Monitor the progress of the task",
            "Collaborate on task with team members",
          ],
        },
        {
          id: "service2",
          // icon: "mdi-file-document",
          icon:<BsPerson style={{fontSize:"2rem"}} />,
          title: "Employee Management",
          description: "It involves the management of organization's workforce",
          child: [
            "Employee profile management",
            "Employee performance tracking",
            "Employee online/offline tracking",
          ],
        },
        {
          id: "service3",
          // icon: "mdi-clock",
          icon:<BsPersonPlus style={{fontSize:"2rem"}} />,
          title: "Client Management",
          description: "It involves managing relationship with clients",
          child: [
            "Store and message customer contact information",
            "Customer service management",
            "Manage client's task",
            "Provide market related information to clients",
            "Notices and notifications",
            "Mobile access",
          ],
        },
      ],
      counters: [
        {
          id: "1",
          extraclass: "",
          start: 15,
          end: 20,
          title: "Employees",
          description:
            "Aliquam egestas vehicula sapien cibus sit amet rutrum dolor molestie.",
        },
        {
          id: "2",
          extraclass: "pt-3",
          start: 800,
          end: 1000,
          title: "Projects",
          description:
            "Pellentesque habitant morbi malesuada fames ac turpis egestas.",
        },
        {
          id: "3",
          extraclass: "pt-3",
          start: 1500,
          end: 2000,
          title: "CLIENTS",
          description:
            "Suspendisse congue risus consequat sapien potitor risus tincidunt.",
        },
      ],
    };
  }

  render() {
    return (
      <React.Fragment>
        <section className="section bg-services" id="services">
          <Container style={{ marginTop: "-3rem" }}>
            <Row>
              <Col lg="12">
                <div className="title-box text-center">
                  <h3 className="title-heading mt-4">
                    Provide Awesome Services{" "}
                  </h3>
                  <p className="text-muted f-17 mt-3">
                    We make sure a deep understanding of the customer's needs
                    and goals, as well as a commitment to continuous improvement
                    and innovation. <br /> We prioritize customer satisfaction
                    and build long-term relationships with their customers based
                    on trust and loyalty.
                  </p>

                  <img src={HomeUrl} height="15" className="mt-3" alt="" />
                </div>
              </Col>
            </Row>
            <Row className="mt-5 pt-4">
              {/* Render Footer Link */}
              {this.state.services.map((item, key) => (
                <Col lg={4} key={key}>
                  <div className="services-box p-4 mt-4 h-100">
                    <div className="services-icon bg-soft-primary">
                      {/* <i className={"mdi text-primary " + item.icon}></i> */}
                      {item.icon}
                    </div>

                    <h5 className="mt-4">{item.title}</h5>
                    <p className="text-muted mt-3">{item.description}</p>
                    <p className="mb-2 f-18">Features</p>
                    <ul>
                      {item.child.map((item, itemkey) => (
                        <li key={itemkey} className="text-muted mt-3">
                          {item}
                        </li>
                      ))}
                    </ul>

                    {/* <div className="mt-3">
                      <Link to="#" className="text-primary f-16">
                        Learn More <i className="mdi mdi-arrow-right ml-1"></i>
                      </Link>
                    </div> */}
                  </div>
                </Col>
              ))}
            </Row>
            {/* <Row className="align-items-center mt-5 pt-4" id="counter">
              <Col lg={6}>
                <div className="pr-4 mt-4">
                  <p className="text-uppercase">why choose us </p>
                  <h3>Manage your Business with Fiscall!</h3>
                  <p className="text-muted mt-3">Morbi at erat et tortor tempor sodales non eu lacus Donec at gravida
                  nunc vitae volutpat libero fusce sit amet mi quis ipsum tempus
                  gravida in quis felis Aliquam fringilla tempus scelerisque Phasellus viverra tincidunt
                  suscipit. Praesent non arcu vitae augue
                            aliquam eget vitae Pellentesque sem mauris porta eget ultrices eros.</p>
                  <div className="mt-4 pt-1">
                    <Link to="#" className="btn btn-outline-primary">Discover More</Link>
                  </div>
                </div>
              </Col>
              <Col lg={5} className="offset-lg-1">
                <div className="counter-box">
                  {this.state.counters.map((counteritem, key) => (
                    <div className={ (counteritem.id !== '1') ? 'mt-4 pt-3' : 'mt-4' } key={key}>
                      <div className="media">
                        <div className="count-box bg-soft-primary text-center">
                          <h3 className="counter-count mb-1 text-primary"> 
                          <CountUp className="counter-value" delay={2} start={counteritem.start} end={counteritem.end} />{" "}
                            <span className="count-plus text-primary"> +</span></h3>
                          <p className="text-uppercase text-muted mb-0 f-14">{counteritem.title} </p>
                        </div>
                        <div className="media-body pl-4">
                          <p className="text-muted mb-0 mt-3">{counteritem.description}</p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </Col>
            </Row> */}
          </Container>
        </section>
      </React.Fragment>
    );
  }
}
