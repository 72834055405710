import Layout1 from "../src/pages/Layout1/Layout1";
import Login from "../src/component/Login";
import SignUp from "../src/component/SignUp";
import ForgotPassword from "../src/component/ForgotPassword";
import TermsAndConditions from "./pages/TermsAndConditions";
import { Privacy } from "./pages/Privacy";

const routes = [
  { path: "/Login", component: Login },
  { path: "/SignUp", component: SignUp },
  { path: "/ForgotPassword", component: ForgotPassword },
  { path: "/terms-and-conditions", component: TermsAndConditions },
  { path: "/privacy-policy", component: Privacy },
  { path: "/", component: Layout1 },
];

export default routes;
