import React, { Component } from "react";
import { Link } from "react-router-dom";

import { Col, Container, Row } from "reactstrap";
import Slider from "react-slick";

import HomeUrl from "../assets/images/home-border.png";

import Img1 from "../assets/images/users/img-1.jpg";
import Img2 from "../assets/images/users/img-2.jpg";
import Img3 from "../assets/images/users/img-3.jpg";
import Img4 from "../assets/images/users/img-4.jpg";

export default class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      teams: [
        {
          id: 1,
          img: Img2,
          name: "Prameshwor Sharma",
          nickname: "Founder/CEO",
          description:
            "I am proud to lead a team of dedicated professionals who are passionate about revolutionizing the financial technology industry.",
        },
        {
          id: 2,
          img: Img1,
          name: "Prabeg Sharma",
          nickname: "Marketing Manager",
          description:
            "As the Marketing Manager of Fiscall, I am responsible for creating and executing strategies that build awareness and drive demand for our financial technology solutions.",
        },

        {
          id: 3,
          img: Img4,
          name: "Rameshwor Sharma",
          nickname: "Managing Director",
          description:
            "As the Managing Director of Fiscall, my primary responsibility is to oversee the overall operations of the company and ensure that we are achieving our strategic goals and objectives.",
        },
        {
          id: 4,
          img: Img3,
          name: "Suman Mali",
          nickname: "Senior Developer",
          description:
            "As a Senior Developer at Fiscall, my role is to design, develop, and maintain innovative and high-quality financial technology solutions that meet the needs of our clients.",
        },
      ],
    };
  }

  render() {
    var temsettings = {
      autoplay: true,
      dots: true,
      speed: 300,
      infinite: false,
      arrows: false,
      slidesToShow: 2,
      slidesToScroll: 1,
    };

    const teamslides = this.state.teams.map((team, teamindex) => {
      return (
        <div className="team-box p-3" key={teamindex}>
          <Row className="align-items-center">
            <Col lg={6}>
              <div className="mt-4">
                <h5 className="mt-2">{team.name}</h5>
                <p className="team-badge bg-primary text-white rounded f-14 mt-2">
                  {team.nickname}
                </p>
                <p className="text-muted mt-3">{team.description}</p>
                {/* <div className="team-social mt-4 pt-2">
                  <ul className="list-inline mb-0">
                    <li className="list-inline-item">
                      <Link to="#" className="text-reset">
                        <i className="mdi mdi-facebook"></i>
                      </Link>
                    </li>
                    <li className="list-inline-item">
                      <Link to="#" className="text-reset">
                        <i className="mdi mdi-twitter"></i>
                      </Link>
                    </li>
                    <li className="list-inline-item">
                      <Link to="#" className="text-reset">
                        <i className="mdi mdi-google"></i>
                      </Link>
                    </li>
                    <li className="list-inline-item">
                      <Link to="#" className="text-reset">
                        <i className="mdi mdi-pinterest"></i>
                      </Link>
                    </li>
                  </ul>
                </div> */}
              </div>
            </Col>
            <Col
              lg={6}
              style={{
                padding: "0",
                height: "100%",
              }}
            >
              <img src={team.img} alt="" className="img-fluid rounded " />
            </Col>
          </Row>
        </div>
      );
    });
    return (
      <React.Fragment>
        <section className="section" id="team">
          <Container style={{ marginTop: "-3rem" }}>
            <Row>
              <Col lg="12">
                <div className="title-box text-center">
                  <h3 className="title-heading mt-4">
                    Meet the People behind Fiscall
                  </h3>
                  <p className="text-muted f-17 mt-3">
                    The people behind Fiscall are a team of experienced
                    professionals with a passion for technology and finance.
                    <br /> They include software engineers, financial analysts,
                    and business experts who work collaboratively to develop
                    cutting-edge products that simplify financial management for
                    their clients.
                  </p>
                  <img src={HomeUrl} height="15" className="mt-3" alt="" />
                </div>
              </Col>
            </Row>
            <Row className="mt-5 pt-4">
              <Col lg="12">
                <div className="team-carousel">
                  <Slider {...temsettings}>{teamslides}</Slider>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <section className="section bg-light bg-cta">
          <Container>
            <Row className="justify-content-center">
              <Col lg={9}>
                <div className="text-center">
                  <h2>
                    Get Started With{" "}
                    <span className="text-primary">Fiscall</span>
                  </h2>
                  <p className="text-muted mt-3">
                    Getting started with Fiscall typically involves cerateing an
                    account, setting up financial accounts, entering financial
                    data, and using the software's various features to manage
                    fincance more effectively. Fiscall is designed to be
                    user-friendly and can be used by individuals and businesses
                    of all sizes.
                  </p>
                  {/* <div className="mt-4 pt-2">
                    <Link
                      to="#"
                      className="btn btn-soft-primary btn-round mr-3 btn-rounded"
                    >
                      Request a service
                    </Link>
                    <Link
                      to="#"
                      className="btn btn-primary btn-round btn-rounded"
                    >
                      Get Started Now
                    </Link>
                  </div> */}
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}
