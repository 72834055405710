import React, { Component } from "react";
import { Row, Col } from "reactstrap";

class Footer_link extends Component {
  render() {
    return (
      // Footer Link start
      <Row>
        <Col lg={12}>
          <div className="text-center">
            <p className="text-muted mb-0">
              {new Date().getFullYear()} © Fiscall. Managed with 💚 by <a href="https://fintax.com.np" target="_blank">Fintax Analytica Pvt. Ltd.</a> || Designed and Developed by <a href="https://apptechnologies.co" target="_blank">App Technologies Pvt. Ltd.</a>
            </p>
          </div>
        </Col>
      </Row>
      //   Footer Link End
    );
  }
}

export default Footer_link;
