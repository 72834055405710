import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import { NavLink } from "reactstrap";
import Scrollspy from "../../component/Navbar/Scrollspy";

// Modal Video
import ModalVideo from "react-modal-video";
import "../../../node_modules/react-modal-video/scss/modal-video.scss";

import HomeUrl from "../../assets/images/home-border.png";
import Img from "../../assets/images/features/img-2.png";

class Section extends Component {
  constructor() {
    super();
    this.state = {
      isOpen: false,
    };
    this.openModal = this.openModal.bind(this);
  }

  openModal() {
    this.setState({ isOpen: true });
  }

  render() {
    return (
      <React.Fragment>
        <section className="bg-home bg-light" id="home">
          <div
            className="home-center"
            style={{ marginTop: "-2.5rem" }}
          >
            <div className="home-desc-center">
              <Container>
                <Row className="align-items-center">
                  <Col lg={6}>
                    <div className="home-content">
                      <p className="mb-0">Company Management</p>
                      <img src={HomeUrl} height="15" alt="" />
                      <h1 className="home-title mt-4">
                        Manage Your Business
                        <br /> Accounting & Taxation{" "}
                      </h1>
                      <p className="text-muted mt-4 f-20">
                        Tired of wasting valuable client-time on spreadsheets,
                        paperwork, and tax returns? The tools in our accounting
                        and tax app help you do all of your financial admin in a
                        fraction of the time
                      </p>
                      <div className="mt-4 pt-2">
                        {/* <Link
                          to="#contact"
                          className="btn btn-primary mr-3 cursor-pointer"
                        >
                          Contact Us
                        </Link> */}

                        {/* <NavLink
                          // href={"#contact"}
                          className="btn btn-primary mr-3 cursor-pointer"
                          style={{ width: "fit-content" }}
                        >
                          Contact Us
                        </NavLink> */}
                        {/* <Link to="#" className="video-play-icon text-dark" onClick={this.openModal}><i className="mdi mdi-play-circle-outline text-dark mr-2"></i>Watch Intro Video</Link> */}
                      </div>
                    </div>
                  </Col>
                  <Col lg={6}>
                    <div className="home-img">
                      <div className="animation-1"></div>
                      <div className="animation-2"></div>
                      <div className="animation-3"></div>
                      <img src={Img} className="img-fluid" alt="" />
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
            <ModalVideo
              channel="vimeo"
              isOpen={this.state.isOpen}
              videoId="99025203"
              onClose={() => this.setState({ isOpen: false })}
            />
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default Section;
