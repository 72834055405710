import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Form, Button, Input } from "reactstrap";
import FooterLink from "../Footer/Footer_link";

import LogoDark from "../../assets/images/logo.png";

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      links: [
        {
          id: 1,
          title: "Services",
          child: [
            { title: "Business Management", link: "#" },
            { title: "Tax Management", link: "#" },
            { title: "Accounting", link: "#" },
            { title: "Workforce Management", link: "#" },
          ],
        },
        {
          id: 2,
          title: "Company",
          child: [
            { title: "Services", link: "#" },
            { title: "Faq", link: "#" },
            { title: "Contact us", link: "#" },
            { title: "Privacy policy", link: "/privacy-policy" },
            { title: "Terms and conditions", link: "/terms-and-conditions" },
          ],
        },
      ],
      email: "",
    };
  }

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
  };

  handleSubmit = (event) => {
    event.preventDefault();

    alert(`${this.state.email} Your email is submitted`);
  };

  render() {
    return (
      <React.Fragment>
        {/* Footer Start */}
        <footer className="section bg-light bg-footer pb-5">
          <Container>
            <Row>
              <Col lg={4}>
                <div className="footer-info mt-4">
                  <img src={LogoDark} alt="" height="22" />
                  <p className="text-muted mt-4 mb-2">
                    Let’s connect on different Social Media! We post a lot about
                    content tips tricks to help save time with your business
                    legal and taxation related works so that you can manage grow
                    your business. Feel free to follow us there.
                  </p>
                  <div className="team-social mt-4 pt-2">
                    <ul className="list-inline mb-0">
                      <li className="list-inline-item">
                        <a
                          target={"_blank"}
                          href="https://www.facebook.com/fintaxanalytica/"
                          className="text-reset"
                        >
                          <i className="mdi mdi-facebook"></i>
                        </a>
                      </li>
                      <li className="list-inline-item">
                        <Link to="#" className="text-reset">
                          <i className="mdi mdi-twitter"></i>
                        </Link>
                      </li>{" "}
                      <li className="list-inline-item">
                        <Link to="#" className="text-reset">
                          <i className="mdi mdi-google"></i>
                        </Link>
                      </li>{" "}
                      <li className="list-inline-item">
                        <Link to="#" className="text-reset">
                          <i className="mdi mdi-pinterest"></i>
                        </Link>
                      </li>{" "}
                    </ul>
                  </div>
                </div>
              </Col>
              <Col lg={4}>
                <Row className="pl-0 md-lg-5">
                  {/* Render Footer Link */}
                  {this.state.links.map((item, key) => (
                    <Col lg={6} key={key}>
                      <div className="mt-4">
                        <h5 className="f-20">{item.title}</h5>
                        <ul className="list-unstyled footer-link mt-3">
                          {item.child.map((linkItem, key) => (
                            <li key={key}>
                              <Link to={linkItem.link} target="_blank">{linkItem.title}</Link>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </Col>
                  ))}
                </Row>
              </Col>
              <Col lg={4}>
                <div className="mt-4">
                  <h5 className="f-20">Subscribe</h5>
                  <div className="subscribe mt-4 pt-1">
                    <Form
                      action="#"
                      method="post"
                      name="contact-form"
                      id="contact-form"
                      onSubmit={this.handleSubmit}
                    >
                      <Input
                        placeholder="Enter Email"
                        type="text"
                        style={{ height: "auto" }}
                        name={"email"}
                        value={this.state.email}
                        onChange={this.handleChange}
                      />
                      <Button
                        color="primary"
                        type="submit"
                        className="btn btn-primary"
                      >
                        <i className="mdi mdi-send"></i>
                      </Button>
                    </Form>
                  </div>
                </div>
              </Col>
            </Row>
            <hr className="my-5" />
            {/* Render Footer Link End */}
            <FooterLink />
          </Container>
        </footer>
        {/* Footer End */}
      </React.Fragment>
    );
  }
}

export default Footer;
